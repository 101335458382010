import React from 'react';
import '../public/output.css'; // 富文本 样式
import "@/styles/globals.css";
import * as Sentry from '@sentry/nextjs';
import Custom500 from './500';
import Head from 'next/head'
import type { AppProps } from 'next/app';
import withTheme from '@/theme/index';
import { App } from 'antd';
import '../public/assets/iconfont/iconfont.css';

if (process.env.NODE_ENV !== 'production' && typeof window !== 'undefined') {
  const ReactDOM = require('react-dom');
  const axe = require('@axe-core/react');
  axe(React, ReactDOM, 1000);
}

function WebsiteApp({ Component, pageProps }: AppProps) {
  return (
    <Sentry.ErrorBoundary fallback={<Custom500 />}>
      <Head>
        <meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1.0, viewport-fit=cover" />
      </Head>
      <App style={{ height: '100%' }}>
        {withTheme(<Component {...pageProps} />)}
      </App>
    </Sentry.ErrorBoundary>
  );
}

export default WebsiteApp;